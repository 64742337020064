<template>
  <header
    class="
      d-flex
      align-items-center
      justify-content-end
      flex-column flex-md-row
    "
  >
    <form
      class="d-flex mb-2 mb-md-0"
      @submit.prevent="searchHandler(searchQuery)"
    >
      <b-input-group>
        <template
          v-if="searchByOptions && searchByOptions.length"
          v-slot:prepend
        >
          <b-form-select
            v-model="selectedSearchBy"
            :options="searchByOptions"
          ></b-form-select>
        </template>
        <b-form-input
          v-model="searchQuery"
          class="search-input w-auto"
          :placeholder="$t('search') || 'Search'"
        />
        <template v-if="searchQuery" v-slot:append>
          <AppButton variant="primary" type="submit">
            <feather-icon icon="SearchIcon" size="12" />

            <span class="sr-only">{{ $t("search") || "Search Only" }}</span>
          </AppButton>
          <AppButton
            variant="danger"
            :label="$t('clear') || 'Clear'"
            @click="resetSearchQueryHandler"
          />
        </template>
      </b-input-group>
    </form>
  </header>
</template>

<script>
import { BInputGroup, BFormInput, BFormSelect } from "bootstrap-vue";
import { AppButton } from "@/components/form/index";
import i18n from "@/libs/i18n/index";

export default {
  components: {
    BInputGroup,
    BFormSelect,
    AppButton,
    BFormInput,
  },
  props: {
    searchByOptions: {
      required: false,
      type: Array,
      default: null,
    },
    initialSelected: {
      type: String,
      default: "name",
    },
  },
  data() {
    return {
      searchQuery: "",
      selectedSearchBy: this.initialSelected,
      // searchQueryTimer: null,
    };
  },
  watch: {
    searchQuery(newValue) {
      newValue === "" && this.$emit("search", "");
    },
  },
  methods: {
    searchHandler(searchText) {
      // debounce
      // clearTimeout(this.searchQueryTimer);
      // this.searchQueryTimer = setTimeout(() => {
      let searchQuery = {};

      if (this.selectedSearchBy) {
        searchQuery[this.selectedSearchBy] = searchText;
      } else {
        searchQuery = searchText && { [this.initialSelected]: searchText };
      }
      this.$emit("search", searchQuery);
      // }, 300);
    },
    resetSearchQueryHandler() {
      this.searchQuery = "";
      this.$emit("search", "");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>