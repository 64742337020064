/**
 * Checks if the Object is a file or not
 * @param {Object} imageFile
 * @returns {Boolean}
 */
export const isFile = (obj) => obj instanceof File

/**
 * Checks whether the arg is an Object
 * @param {Object} obj
 * @returns {Boolean}
 * @testSuite {Utils.isObject}
 */
export const isObject = (obj) => !!(obj && typeof obj === "object" && !Array.isArray(obj))


/**
 * Checks whether the arg is an String
 * @param {String} str
 * @returns {Boolean}
 * @testSuite {Utils.isString}
 */
export const isString = (str) => !!(str && typeof str === "string")


export const isIterable = (obj) => {
    if (!obj) return false
    if (isObject(obj)) return Object.keys(obj).length > 0
    return Array.isArray(obj)
}

/**
 * from ArrayToFormData
 * @param {Array, _key , CB}
 * @returns {Object}
 */
export const fromArrayToFormData = (arr, _k, _formDataCB) => {
    if (!arr) return null
    if (!Array.isArray(arr)) return arr

    const arrObject = {}

    arr.forEach((obj, index) => {
        // console.log("🚀 ~ file: index.js ~ line 44 ~ arr.forEach ~ obj, index", obj, index)
        if (Object.keys(obj).includes("value") || Object.keys(obj).includes("uuid")) {
            arrObject[`${_k}[${index}]`] = obj['value'] || obj['uuid']
            return
        }



        Object.keys(obj).forEach((key) => {
            if (isString(obj)) {
                arrObject[`${_k}[${index}]`] = obj
                return
            }

            if (isFile(obj)) {
                arrObject[`${_k}[${index}]`] = obj
                return
            }

            if (!isObject(obj[key])) {
                arrObject[`${_k}[${index}][${key}]`] = obj[key]
                return
            }

            // if the value is object and the object has the key [value] => append it to the formData 
            if (Object.keys(obj[key]).includes("value") || Object.keys(obj[key]).includes("uuid")) {
                arrObject[`${_k}[${index}][${key}]`] = obj[key]['value'] || obj[key]['uuid']
                return
            }

            // if the value is object => convert it to formData 
            Object.keys(obj[key]).forEach((keyNested2) => {
                arrObject[`${_k}[${index}][${key}][${keyNested2}]`] = obj[key][keyNested2]
            })
        })
    })

    return arrObject
}

/**
 * transform Component data to formData format before submit
 * @param {Object} Data
 * @returns {FormData}
 */
export const toFormData = (obj) => {
    // TODO : Check for camelCase keys and change it to snake_case
    // console.log("🚀 ~ file: index.js ~ line 62 ~ toFormData ~ obj", obj)

    /* TODO :
            inputs: data
            outputs: formData obj
    
             1- It should return the data if it is empty
             2- If the data is array It should loop through its  elements and return it as an object of formDataKeys {
                 key[index]: arr[index]
                }
             3- If the data is object It should loop though its keys and return an object of formDataKeys {
             key:obj[key]
             }
             4- If the array has an object it should go to number 3
             5- If the object has an array it should go to number 2
          */

    const formData = new FormData();

    Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === "undefined" || obj[key] === null) return;
        // console.log("🚀 ~ file: index.js ~ line 111 ~ Object.keys ~ obj[key]", obj[key])

        if (
            obj[key] &&
            Array.isArray(obj[key].value || obj[key])
        ) {
            const arrObj = fromArrayToFormData((obj[key].value || obj[key]), key);

            Object.keys(arrObj).forEach((k) => {
                if (arrObj[k] === null) return;
                formData.append(k, arrObj[k])
            })
        } else if (isFile(obj[key])) {
            formData.append(key, obj[key]);
            // } else if (!obj[key] || obj[key] === "") {
            // do nothing
            // Silence is golden
        } else if (isObject(obj[key]) && !(Object.keys(obj[key]).includes("value") || Object.keys(obj[key]).includes("uuid"))) {
            Object.keys(obj[key]).forEach((keyNested) => {
                if (obj[key][keyNested] === null) return;

                if (isObject(obj[key][keyNested])) {
                    // Object.keys(obj[key][keyNested]).forEach((keyNested2) => {
                    // if (obj[key][keyNested][keyNested2] === null) return;

                    if (Object.keys(obj[key][keyNested]).includes("value") || Object.keys(obj[key][keyNested]).includes("uuid")) {
                        formData.append(`${key}[${keyNested}]`, obj[key][keyNested]['value'] || obj[key][keyNested]['uuid'])
                        return
                    }

                    // formData.append(`${key}[${keyNested}][${keyNested2}]`, obj[key][keyNested][keyNested2])
                    // return
                    // })
                } else {
                    formData.append(`${key}[${keyNested}]`, obj[key][keyNested])
                }
            })
        } else {
            formData.append(
                key,
                obj[key].value || obj[key].uuid || obj[key]
            );
        }
    });

    return formData;
};


/**
 * checks if the APP is RTL
 * @param {Boolean, String}
 * @returns {Boolean}
 * @testSuite {Utils.isRTL}
 */
export const isRTL = (forceRTL = false, lang = null) => {
    const local = window.localStorage?.getItem('language')

    if (forceRTL) return forceRTL;
    if (lang) return lang === 'ar'
    if (local && local !== 'ar') return false;

    return true
}

/**
 * transform data from server to Component data format
 * @param {Object} Data
 * @returns {Object}
 */
export const populateForm = (obj) => {
    const data = {};

    Object.keys(JSON.parse(JSON.stringify(obj))).map((key) => {
        if (typeof obj[key] === "undefined" || obj[key] === null) return;

        if (Array.isArray(obj[key])) {
            data[key] = obj[key]
            return data[key]
        }


        if (typeof obj[key] === "object") {
            data[key] = {
                label: isRTL()
                    ? obj[key].name_ar || obj[key].name
                    : obj[key].name,
                text: isRTL()
                    ? obj[key].name_ar || obj[key].name
                    : obj[key].name,
                value: obj[key].uuid,
                ...obj[key]
            };
            return data[key]
        }

        data[key] = obj[key];
        return data[key]
    });

    return JSON.parse(JSON.stringify(data));
};

/**
 * format prices to be readable for users 1000 => 1,000EGP
 * @param {number} price
 * @returns {String}
 * @testSuite {Utils.formatPrice}
 */
export const formatPrice = (price, _isRTL = isRTL()) => Number(price)?.toLocaleString(_isRTL ? "ar-EG" : "en-US", {
    style: "currency",
    currency: _isRTL ? "EGP" : 'USD',
});

export const resetObject = (objct, _isRTL = isRTL()) => {
    //
};
