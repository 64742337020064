<template>
  <div class="mx-2 my-2">
    <b-row>
      <b-col
        cols="12"
        sm="6"
        class="
          d-flex
          align-items-center
          justify-content-center justify-content-sm-start
        "
      >
        <span v-if="meta" class="text-muted">
          {{ $t("total_entries", { total: meta.total }) }}
        </span>
      </b-col>
      <!-- Pagination -->
      <b-col
        cols="12"
        sm="6"
        class="
          d-flex
          align-items-center
          justify-content-center justify-content-sm-end
        "
      >
        <b-pagination
          v-if="meta && meta.total"
          v-model="meta.current_page"
          :total-rows="meta.total"
          :per-page="meta.per_page"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
          @change="changePaginationHandler"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BPagination, BCol, BRow } from "bootstrap-vue";

export default {
  components: {
    BPagination,
    BCol,
    BRow,
  },
  props: {
    meta: {
      type: Object,
      default: null,
    },
  },
  methods: {
    changePaginationHandler(page) {
      this.$emit("change-pagination", page);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>